.vHead {
  margin: 0 !important;
  margin-right: 3px !important;
  padding: 3px;
  font-size: 13px;
  display: flex;
  align-items: center;
  text-transform: none;
  height: 87%;
  border-bottom: 1px solid grey;
}

.vCell {
  margin: 0 !important;
  margin-right: 3px !important;
  padding: 3px;
  font-size: 13px;
  display: flex;
  align-items: center;
  height: 87%;
}

.actionCell {
  justify-content: center;
}

.msideCell {
  /* padding: 0px; */
  /* border-bottom: 1px solid white; */
}

.sideCell {
  width: 100%;
  font-weight: bold;
  padding: 3px;
}

.vRow {
  /* margin-bottom: 3px !important; */
}

.vRow:nth-of-type(n):hover .vCell {
  background-color: rgba(27, 117, 188, 0.25);
}

.vRow:nth-of-type(odd) .vCell {
  background-color: rgba(255,255,255,0.03);
}

.vRow:nth-of-type(even) .vCell {
  background-color: rgba(0,0,0,0.03);
}
