.container{
/* height: 100%; */
align-content: center;
}

.card{
height: 280px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
/* background-color: #252120 !important; */
}

.input-group-prepend span{
width: 50px;
background-color: rgb(27, 117, 188);
color: white;
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;
}

.inputStyle{
  /* color: white !important;
  background-color: #252120 !important; */
}

.login_btn{
color: white !important;
background-color: rgb(27, 117, 188) !important;
}

.login_btn:hover{
color: white !important;
background-color: #14CC9D !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(27, 117, 188);
  width: 120px;
  height: 120px;
  /* margin-top: 50px; */
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
