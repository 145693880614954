.priceBlink {
  /* animation: blinkPrice 1s linear; */
}

@keyframes blinkPrice{
  0% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.6);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.2);
  }
}


/* .newprx-appear {
  background-color: red;
}

.newprx-appear-active {
  background-color: rgba(255,0,0,0);
  transition: background-color 700ms;
} */

.newprx-enter {
  background-color: red;
}

.newprx-enter-active {
  background-color: rgba(255,0,0,0);
  transition: background-color 700ms;
}

/* .newprx-exit {
  opacity: 1;
}

.newprx-exit-active {
  opacity: 0;
  transition: opacity 700ms;
} */
