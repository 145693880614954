.successButton {
  background-color: rgb(27, 117, 188);
  color: white;
  border-radius: 4px;
  text-align: center;
  width: 25%;
  padding: 0.25em;
  cursor: pointer;
  align-self: end;
}

.successButton:hover {
  background-color: #0e4978;
}

.settingsContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  /* background-color: #252120; */
  color: white;
}

.sideBar {
  flex: 1;
  display: flex;
  flex-direction: column;
  border-right: 1px solid grey;
  padding-top: 1em;
}

.sideBarItem {
  padding-left: 1em;
  cursor: pointer;
  height: 30px;
  display: flex;
  align-items: center;
}

.settings {
  flex: 3;
  padding: 1em;
  display: flex;
  flex-direction: column;
}

.settingsContent {
  flex-grow: 1;
}

.selected {
  background-color: rgb(27, 117, 188);
  color: white;
}
