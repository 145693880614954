.inputContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.numberContainer {
  display: flex;
  flex: 3;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding-right: 0.25em;
}

.spinnerContainer {
  display: flex;
  flex: 0.5;
  flex-direction: column;
}

.qtySpinnerStyle {
  width: 100%;
  height: 75%;
  text-align: right;
}
