.errorButton {
  background-color: #ff214d;
  color: white;
  border-radius: 4px;
  text-align: center;
  width: 25%;
  float: right;
  padding: 0.25em;
  cursor: pointer;
}

.errorButton:hover {
  background-color: #b53030;
}
