.buyButton:hover {
  background-color: rgba(255,255,255,0.15);
}

.sellButton:hover {
  background-color: rgba(255,255,255,0.15);
}

.screenButton:hover {
  background-color: rgba(255,255,255,0.15);
}

.buyButton {
  box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.sellButton {
  box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.screenButton {
  box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-size: calc(10px + 0.7vw) !important;
}

.pipFormat {
  font-weight: bold;
  font-size: calc(3px + 1.7vw);
}

.orderContainer {
  font-size: calc(1px + 0.90vw);
}

.buttonFont {
  font-size: calc(1px + 0.90vw) !important;
}

.titleFont {
  font-size: 13px;
}

.dropdownContent {
  position: absolute;
  right:0;
  background-color: #f9f9f9;
  width:150px;
  /* height:100px; */
  border: 1px solid grey;
  border-top-color: #ffbf00;
  border-top-width: 4px;
  border-radius: 5px;
  color:black;
  font-size: 14px;
  z-index: 2000;
}

.pointer:hover {
  cursor: pointer;
}

.sellAgr {
  /* color: #e53d3d; */
  /* box-shadow:0px 0px 0px 1px #e53d3d inset; */
}

.sellAgr:hover {
  /* color: white; */
  /* background-color: #e53d3d; */
  cursor: pointer;
}

.buyAgr {
  /* color: #5785f9; */
  /* box-shadow:0px 0px 0px 1px #5785f9 inset; */
}

.buyAgr:hover {
  /* color: white; */
  /* background-color: #5785f9; */
  cursor: pointer;
}

.sellAgrDisabled {
  color: grey;
  box-shadow:0px 0px 0px 1px grey inset;
}

.sellAgrDisabled:hover {
  color: white;
  background-color: grey;
  cursor: not-allowed;
}

.buyAgrDisabled {
  color: grey;
  box-shadow:0px 0px 0px 1px grey inset;
}

.buyAgrDisabled:hover {
  color: white;
  background-color: grey;
  cursor: not-allowed;
}

.submitButton {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}

.submitButton:hover {
  cursor: pointer;
}
