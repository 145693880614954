.ticketContainer {
  z-index: 900;
  position: absolute;
  /* height: 410px; */
  width: 350px;
  padding: 10px;
  border: 1px solid grey;
}

.closeBtn:hover {
  cursor: pointer;
}

.customInputDark {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0px;
  border-bottom: 2px solid rgb(190,190,190);
  background: rgba(255, 255, 255, 0);
  color: black;
}

.customInputDark:focus {
  outline: none;
  border-bottom: 2px solid black;
}

.customInputDark:hover:not(:focus) {
  background-color: rgba(50,50,50,0.1);
}

.ticketContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.radioRow {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.sideCol {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.typeCol {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.tifCol {
  flex: 2;
  display: flex;
  flex-direction: column;
}

.radioItemCol {
  /* flex: 1; */
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0.5em;
}

.radioItemRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  padding-right: 0.5em;
}

.tifRow {
  display: flex;
  flex-direction: row;
}

.inputCol {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.inputRow {
  display: flex;
  flex-direction: row;
}

.expandRow {
  display: flex;
  justify-content: center;
  align-items: center;
}

.center {
  justify-content: center;
  align-items: center;
}
