.customBgGrey {
  /* background-color: #29374a !important; */
  background-color: #252120 !important;
  /* #252120 */
}

.customBgBlue {
  background-color: rgb(27, 117, 188) !important;
}

.customBgBuy {
  background-color: #5785f9 !important;
}

.customBgSell {
  background-color: #e53d3d !important;
}

.customBgScreen {
  background-color: #3f844b !important;
}

body {
  /* font-family: 'Lato', sans-serif !important; */
  background:
    -webkit-radial-gradient(black 15%, transparent 16%) 0 0,
    -webkit-radial-gradient(black 15%, transparent 16%) 8px 8px,
    -webkit-radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
    -webkit-radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background:
    radial-gradient(black 15%, transparent 16%) 0 0,
    radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color:#282828 !important;
  background-size:16px 16px;
}

.react-grid-item.react-grid-placeholder {
  background: purple !important;
}

.react-grid-item > .react-resizable-handle::after {
  width: 7px !important;
  height: 7px !important;
  border-right: 2px solid rgba(255, 255, 255, 0.9) !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.9) !important;
}

.errTooltip {
  position: relative;
  right: 0;
}



.customTable {
  border-collapse: separate;
  border-spacing: 3px;
  width: 100%;
  font-size: 14px;
}

.customTable th {
  border-bottom: 1px solid grey;
  /* background-color: #252120; */
}

.customTable td,th {
  padding: 5px;
}

.customTable tr {
  line-height: 13px;
}

.customTable tr:hover {
  background-color: rgba(27, 117, 188, 0.25) !important;
}

.customTable tr:nth-child(even) {
  background-color: rgba(0,0,0,0.03);
}

.customTable tr:nth-child(odd) {
  background-color: rgba(255,255,255,0.03);
}

.customInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0px;
  border-bottom: 2px solid rgb(140,140,140);
  background: rgba(255, 255, 255, 0);
  color: white;
}

.customInput:focus {
  outline: none;
  border-bottom: 2px solid white;
}

.customInput:hover:not(:focus) {
  background-color: rgba(140,140,140,0.05);
}

.quoteButton {
  font-weight: bold !important;
}

.layout {
  width: 99vw;
}

.buyCell {
  box-shadow: inset 0px 0px 25px 3px #166ABD;
  -webkit-box-shadow: inset 0px 0px 25px 3px #166ABD;
  -moz-box-shadow: inset 0px 0px 25px 3px #166ABD;
}

.sellCell {
  box-shadow: inset 0px 0px 25px 3px #BD3300;
  -webkit-box-shadow: inset 0px 0px 25px 3px #BD3300;
  -moz-box-shadow: inset 0px 0px 25px 3px #BD3300;
}

.box-content{
  width: 100%;
  height: calc(100% - 1.5em - 16px);
  overflow: auto;
}

.pull:hover{
  color: #dc3545;
  cursor: pointer;
}

.revise:hover{
  color: #ffbf00;
  cursor: pointer;
}

.stickyhead{
  position: sticky;
  position: -webkit-sticky;
  top:0;
}

.selectedTab{
  color: rgb(27, 117, 188) !important;
  /* border-bottom: 2px solid rgb(27, 117, 188); */
}

.modalPadding{
  padding: 1em;
}

.blink{
  -webkit-animation: blink 1s linear infinite;
          animation: blink 1s linear infinite;
}

@-webkit-keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}

@keyframes blink{
0%{opacity: 0;}
50%{opacity: .5;}
100%{opacity: 1;}
}

.errorButton {
  background-color: #ff214d;
  color: white;
  border-radius: 4px;
  text-align: center;
  width: 25%;
  float: right;
  padding: 0.25em;
  cursor: pointer;
}

.errorButton:hover {
  background-color: #b53030;
}

.buyButton:hover {
  background-color: rgba(255,255,255,0.15);
}

.sellButton:hover {
  background-color: rgba(255,255,255,0.15);
}

.screenButton:hover {
  background-color: rgba(255,255,255,0.15);
}

.buyButton {
  box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.sellButton {
  box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.screenButton {
  box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -webkit-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  -moz-box-shadow: inset 0px 0px 25px 3px rgba(0,0,0,0.25);
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  font-size: calc(10px + 0.7vw) !important;
}

.pipFormat {
  font-weight: bold;
  font-size: calc(3px + 1.7vw);
}

.orderContainer {
  font-size: calc(1px + 0.90vw);
}

.buttonFont {
  font-size: calc(1px + 0.90vw) !important;
}

.titleFont {
  font-size: 13px;
}

.dropdownContent {
  position: absolute;
  right:0;
  background-color: #f9f9f9;
  width:150px;
  /* height:100px; */
  border: 1px solid grey;
  border-top-color: #ffbf00;
  border-top-width: 4px;
  border-radius: 5px;
  color:black;
  font-size: 14px;
  z-index: 2000;
}

.pointer:hover {
  cursor: pointer;
}

.sellAgr {
  /* color: #e53d3d; */
  /* box-shadow:0px 0px 0px 1px #e53d3d inset; */
}

.sellAgr:hover {
  /* color: white; */
  /* background-color: #e53d3d; */
  cursor: pointer;
}

.buyAgr {
  /* color: #5785f9; */
  /* box-shadow:0px 0px 0px 1px #5785f9 inset; */
}

.buyAgr:hover {
  /* color: white; */
  /* background-color: #5785f9; */
  cursor: pointer;
}

.sellAgrDisabled {
  color: grey;
  box-shadow:0px 0px 0px 1px grey inset;
}

.sellAgrDisabled:hover {
  color: white;
  background-color: grey;
  cursor: not-allowed;
}

.buyAgrDisabled {
  color: grey;
  box-shadow:0px 0px 0px 1px grey inset;
}

.buyAgrDisabled:hover {
  color: white;
  background-color: grey;
  cursor: not-allowed;
}

.submitButton {
  height: 100%;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  border-radius: 4px;
}

.submitButton:hover {
  cursor: pointer;
}

.priceBlink {
  /* animation: blinkPrice 1s linear; */
}

@-webkit-keyframes blinkPrice{
  0% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.6);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.2);
  }
}

@keyframes blinkPrice{
  0% {
    background-color: rgba(255, 0, 0, 0.8);
  }
  50% {
    background-color: rgba(255, 0, 0, 0.6);
  }
  100% {
    background-color: rgba(255, 0, 0, 0.2);
  }
}


/* .newprx-appear {
  background-color: red;
}

.newprx-appear-active {
  background-color: rgba(255,0,0,0);
  transition: background-color 700ms;
} */

.newprx-enter {
  background-color: red;
}

.newprx-enter-active {
  background-color: rgba(255,0,0,0);
  -webkit-transition: background-color 700ms;
  transition: background-color 700ms;
}

/* .newprx-exit {
  opacity: 1;
}

.newprx-exit-active {
  opacity: 0;
  transition: opacity 700ms;
} */

.inputContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.numberContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 3 1;
          flex: 3 1;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
  padding-right: 0.25em;
}

.spinnerContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.qtySpinnerStyle {
  width: 100%;
  height: 75%;
  text-align: right;
}

.dateContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 3 1;
          flex: 3 1;
  padding-right: 0.25em;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100%;
}

.fakeContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
}

.ticketContainer {
  z-index: 900;
  position: absolute;
  /* height: 410px; */
  width: 350px;
  padding: 10px;
  border: 1px solid grey;
}

.closeBtn:hover {
  cursor: pointer;
}

.customInputDark {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0px;
  border-bottom: 2px solid rgb(190,190,190);
  background: rgba(255, 255, 255, 0);
  color: black;
}

.customInputDark:focus {
  outline: none;
  border-bottom: 2px solid black;
}

.customInputDark:hover:not(:focus) {
  background-color: rgba(50,50,50,0.1);
}

.ticketContent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.radioRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%;
}

.sideCol {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.typeCol {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.tifCol {
  -webkit-flex: 2 1;
          flex: 2 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.radioItemCol {
  /* flex: 1; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 0.5em;
}

.radioItemRow {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  padding-right: 0.5em;
}

.tifRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.inputCol {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.inputRow {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}

.expandRow {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.center {
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.reviseContainer {
  z-index: 900;
  position: absolute;
  /* height: 230px; */
  width: 350px;
  padding: 10px;
  border: 1px solid grey;
}

.vHead {
  margin: 0 !important;
  margin-right: 3px !important;
  padding: 3px;
  font-size: 13px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  text-transform: none;
  height: 87%;
  border-bottom: 1px solid grey;
}

.vCell {
  margin: 0 !important;
  margin-right: 3px !important;
  padding: 3px;
  font-size: 13px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  height: 87%;
}

.actionCell {
  -webkit-justify-content: center;
          justify-content: center;
}

.msideCell {
  /* padding: 0px; */
  /* border-bottom: 1px solid white; */
}

.sideCell {
  width: 100%;
  font-weight: bold;
  padding: 3px;
}

.vRow {
  /* margin-bottom: 3px !important; */
}

.vRow:nth-of-type(n):hover .vCell {
  background-color: rgba(27, 117, 188, 0.25);
}

.vRow:nth-of-type(odd) .vCell {
  background-color: rgba(255,255,255,0.03);
}

.vRow:nth-of-type(even) .vCell {
  background-color: rgba(0,0,0,0.03);
}

.successButton {
  background-color: rgb(27, 117, 188);
  color: white;
  border-radius: 4px;
  text-align: center;
  width: 25%;
  padding: 0.25em;
  cursor: pointer;
  -webkit-align-self: end;
          align-self: end;
}

.successButton:hover {
  background-color: #0e4978;
}

.settingsContainer {
  width: 100%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /* background-color: #252120; */
  color: white;
}

.sideBar {
  -webkit-flex: 1 1;
          flex: 1 1;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  border-right: 1px solid grey;
  padding-top: 1em;
}

.sideBarItem {
  padding-left: 1em;
  cursor: pointer;
  height: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.settings {
  -webkit-flex: 3 1;
          flex: 3 1;
  padding: 1em;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.settingsContent {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.selected {
  background-color: rgb(27, 117, 188);
  color: white;
}

.container{
/* height: 100%; */
-webkit-align-content: center;
        align-content: center;
}

.card{
height: 280px;
margin-top: auto;
margin-bottom: auto;
width: 400px;
/* background-color: #252120 !important; */
}

.input-group-prepend span{
width: 50px;
background-color: rgb(27, 117, 188);
color: white;
border:0 !important;
}

input:focus{
outline: 0 0 0 0  !important;
box-shadow: 0 0 0 0 !important;
}

.inputStyle{
  /* color: white !important;
  background-color: #252120 !important; */
}

.login_btn{
color: white !important;
background-color: rgb(27, 117, 188) !important;
}

.login_btn:hover{
color: white !important;
background-color: #14CC9D !important;
}

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid rgb(27, 117, 188);
  width: 120px;
  height: 120px;
  /* margin-top: 50px; */
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

