.customTable {
  border-collapse: separate;
  border-spacing: 3px;
  width: 100%;
  font-size: 14px;
}

.customTable th {
  border-bottom: 1px solid grey;
  /* background-color: #252120; */
}

.customTable td,th {
  padding: 5px;
}

.customTable tr {
  line-height: 13px;
}

.customTable tr:hover {
  background-color: rgba(27, 117, 188, 0.25) !important;
}

.customTable tr:nth-child(even) {
  background-color: rgba(0,0,0,0.03);
}

.customTable tr:nth-child(odd) {
  background-color: rgba(255,255,255,0.03);
}

.customInput {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 0px;
  border-bottom: 2px solid rgb(140,140,140);
  background: rgba(255, 255, 255, 0);
  color: white;
}

.customInput:focus {
  outline: none;
  border-bottom: 2px solid white;
}

.customInput:hover:not(:focus) {
  background-color: rgba(140,140,140,0.05);
}

.quoteButton {
  font-weight: bold !important;
}

.layout {
  width: 99vw;
}

.buyCell {
  box-shadow: inset 0px 0px 25px 3px #166ABD;
  -webkit-box-shadow: inset 0px 0px 25px 3px #166ABD;
  -moz-box-shadow: inset 0px 0px 25px 3px #166ABD;
}

.sellCell {
  box-shadow: inset 0px 0px 25px 3px #BD3300;
  -webkit-box-shadow: inset 0px 0px 25px 3px #BD3300;
  -moz-box-shadow: inset 0px 0px 25px 3px #BD3300;
}

.box-content{
  width: 100%;
  height: calc(100% - 1.5em - 16px);
  overflow: auto;
}

.pull:hover{
  color: #dc3545;
  cursor: pointer;
}

.revise:hover{
  color: #ffbf00;
  cursor: pointer;
}

.stickyhead{
  position: sticky;
  position: -webkit-sticky;
  top:0;
}

.selectedTab{
  color: rgb(27, 117, 188) !important;
  /* border-bottom: 2px solid rgb(27, 117, 188); */
}

.modalPadding{
  padding: 1em;
}
