.customBgGrey {
  /* background-color: #29374a !important; */
  background-color: #252120 !important;
  /* #252120 */
}

.customBgBlue {
  background-color: rgb(27, 117, 188) !important;
}

.customBgBuy {
  background-color: #5785f9 !important;
}

.customBgSell {
  background-color: #e53d3d !important;
}

.customBgScreen {
  background-color: #3f844b !important;
}

body {
  /* font-family: 'Lato', sans-serif !important; */
  background:
    radial-gradient(black 15%, transparent 16%) 0 0,
    radial-gradient(black 15%, transparent 16%) 8px 8px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 0 1px,
    radial-gradient(rgba(255,255,255,.1) 15%, transparent 20%) 8px 9px;
  background-color:#282828 !important;
  background-size:16px 16px;
}

.react-grid-item.react-grid-placeholder {
  background: purple !important;
}

.react-grid-item > .react-resizable-handle::after {
  width: 7px !important;
  height: 7px !important;
  border-right: 2px solid rgba(255, 255, 255, 0.9) !important;
  border-bottom: 2px solid rgba(255, 255, 255, 0.9) !important;
}

.errTooltip {
  position: relative;
  right: 0;
}
